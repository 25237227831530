import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import Colors from '../../utils/Colors';

const isMac = typeof window !== `undefined` && window?.navigator.userAgent.includes('Mac');

export const HideNotificationAnim = keyframes`
  to {
    visibility: hidden;
    height: 0;
  }
`;

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  padding:0;
  margin:0;
}

button{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--text-primary);
  font: inherit;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}

.grecaptcha-badge { 
    visibility: hidden;
}

a{
  text-decoration: none;
  color: var(--text-primary);
  outline: none;
}



/* reset a element styles on states hover and focus*/

&:root {
  --white: #ffffff;
  --black: #000000;
  --text-primary: #000000;
  --primary-gray: #c0c0c0;
  --secondary-gray: #c0c0c0;
  --static-white: #ffffff;
  --static-black: #000000;
  --dark-gray: #404040;
  --error: #c60000;
  --default-gray-background: #f4f4f4;
}

body{
  font-family: "Manrope", sans-serif;
  color: var(--text-primary);
  overflow-x: hidden;
    margin: 0;
    padding: 0;
}


* {
    font-family: 'Manrope',-apple-system,'Arial';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    overflow: hidden;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    visibility: none;
    display: none;
  }

  .notification {
    animation: ${HideNotificationAnim} 3s ease-in 1s forwards;
  }
  .notification__item--success {
    background-color: var(--primary);
    border-left: 8px solid darken(var(--primary), 15%);
  }
  .notification__item--success .notification__timer {
    background-color: var(--grey);
  }

  ${
    !isMac &&
    css`
      *::-webkit-scrollbar {
        background-color: none;
        width: 6px;
        height: 6px;
      }
      *::-webkit-scrollbar-track {
        background-color: none;
      }
      *::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
      }
      *::-webkit-scrollbar-button {
        display: none;
      }
    `
  }
  p {
    margin: 0;
    padding: 0;
  }
  input:disabled {
    border: none !important;
    background: var(--gray-background);
  }
  [class^='notification__item'], [class*=' notification__item'] {
    border: none;
  }
  .notification__timer-filler {
    opacity: 0;
  }
  .animate__fadeIn {
    opacity: 1;
    transition: opacity .5s ease;
  }
  .animate__fadeOut {
    z-index: 0;
    opacity: 0;
    transition: opacity .5s ease;
  }
  .wrapper-class {
    border: 1px solid black;
    border-radius: 4px;
    margin-bottom: 24px;
  }
  .editor-class {
    padding: 1rem;
    height: 400px;
    overflow: scroll;
  }
  .toolbar-class {
  }
  .rdw-option-wrapper {
    border: none;
    padding: 1px;
    min-width: 25px;
    height: 20px;
    border-radius: 4px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    &:hover{
      box-shadow: none;
      background: #e7e7e7;
    }
  }
`;

export const WrapperDefault = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  @media (max-width: 1200px) {
    padding: 0 24px 0 24px;
  }
`;

export const ContainerDefault = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ErrorMessage = styled.span<{ noMargin?: boolean }>`
  color: var(--error);
  font-size: 12px;
  margin: 9.5px 0 14px 0;
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}
`;

export const Line = styled.div`
  margin: 3px 0;
  margin-bottom: 6px;
  min-height: 1px;
  height: 1px;
  background-color: #d7d7d7;
`;

export const TermsAndConditionsButton = styled.div`
  color: ${Colors.white};
  width: auto;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 4px;
  @media (max-width: 768px) {
    font-size: 14px;
    width: auto;
  }
`;

export const Text = styled.span`
  display: flex;
  font-size: 14px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
